<template>
  <toggleSwitch2
    :title="$t('settings.showAppointments.title')"
    :help="$t('settings.showAppointments.info')"
    :value="data"
    :callback="setValue"
  />
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      data: this.$store.state.business.unsaved.showAppointments
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setValue(val) {
      this.data = val;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.showAppointments = this.data;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
